/*css初始化*/

/*清除内外边距*/

body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td,
img,
div {
  margin: 0;
  padding: 0;
  border: medium none;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
}

em {
  font-style: normal;
}

/*重置列表元素*/

ul,
li {
  list-style: none;
}

/*重置超链接元素*/

a {
  text-decoration: none;
  color: #333333;
}

a:hover {
  text-decoration: underline;
  color: #f40;
}

/*重置图片元素*/

img {
  border: 0;
  vertical-align: middle;
}

/*重置表格元素*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  -webkit-overflow-scroll: touch;
}

body p {
  margin: 0;
  padding: 0;
}

body {
  /* padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right); */
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  /* 字体抗锯齿渲染*/
  -webkit-text-size-adjust: none;
  /*禁用iPhone中Safari的字号自动调整*/
  -webkit-overflow-scrolling: touch;
  /* 解决IOS默认滑动很卡的情况 */
  -webkit-touch-callout: none;
  /* 触摸或长按目标时禁止显示系统默认菜单 */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

html {
  overflow-y: scroll;
}

.css-19tu4nq-skeletonStyles-Skeleton,
.css-4o0m1c-SkeletonTheme > p {
  margin-top: 0.213333rem;
}

.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
  /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

/*全局按钮设置-start*/

.disable-button-inner {
  background: #EDEEED !important;
  color: #999999 !important;
  margin: 1.28rem 0.026667rem 0 0.026667rem;
  height: 1.066667rem !important;
  line-height: 1.066667rem !important;
  font-size: 0.453333rem !important;
  letter-spacing: 0.053333rem;
  border-radius: 0 !important;
}

.enable-button-inner {
  background: #0EB0AA !important;
  color: #ffffff !important;
  margin: 1.28rem 0.026667rem 0 0.026667rem;
  height: 1.066667rem !important;
  line-height: 1.066667rem !important;
  font-size: 0.453333rem !important;
  letter-spacing: 0.053333rem;
  border-radius: 0 !important;
}

.disable-button-inner-rounded {
  background: #757575 !important;
  color: #FFFFFF !important;
  margin: 0;
  height: 1.25rem !important;
  line-height: 1.25rem !important;
  font-size: 0.48rem !important;
  letter-spacing: 0.053333rem;
  border-radius: 0.13rem !important;
}

.enable-button-inner-rounded {
  background: #2196F3 !important;
  color: #ffffff !important;
  height: 1.25rem !important;
  line-height: 1.25rem !important;
  font-size: .48rem !important;
  letter-spacing: 0.053333rem;
  border-radius: .13rem !important;
}

/*全局按钮设置-end*/

/*全局codeForm设置-start*/

.code-form .am-list-item {
  border: none;
  padding: 0 0.133333rem;
}

.code-form .am-list-item .am-input-label {
  font-weight: bold;
}

.code-form .am-list-item .am-input-item {
  height: 1.28rem;
  padding-left: 0.4rem;
  font-size: 0.4rem !important;
}

/*全局codeForm设置-end*/

/*全局infoModal设置-start*/

.info-modal {
  width: 8.266667rem !important;
}

.info-modal .am-modal-content {
  border-radius: 0.266667rem !important;
  padding-top: 0 !important;
}

.info-modal .am-modal-content .am-modal-body {
  padding: 0 !important;
  font-size: 0.453333rem !important;
}

.info-modal .am-modal-content .am-modal-body span {
  color: #000000;
  display: block;
  height: 0.64rem;
  margin: 0.693333rem 0.426667rem;
  font-size: 0.586667rem;
}

.info-modal .am-modal-content .am-modal-body p {
  line-height: 0.64rem;
  color: #202020 !important;
  margin: 0 0.426667rem;
}

.info-modal .am-modal-footer {
  margin-top: 0.693333rem;
}

body .am-list-body .am-list-item .am-input-label,
body .am-list-body .am-list-item .am-input-control input,
body .am-list-body .am-list-item .am-input-control {
  font-size: 0.426667rem;
  padding: 0.053333rem 0;
}

body .am-list-body div:not(:last-child) .am-list-line {
  border-bottom: none;
}

/*全局infoModal设置-end*/

/*全局inputModal设置-start*/

body .ios-input-modal {
  align-items: flex-start;
  top: 12vh;
}

.input-modal {
  width: 8.266667rem !important;
}

.input-modal .am-modal-content {
  border-radius: 0.266667rem !important;
  padding-top: 0 !important;
}

.input-modal .am-modal-content .am-modal-body {
  padding: 0 !important;
  font-size: 0.453333rem !important;
}

.input-modal .am-modal-content .am-modal-body span {
  color: #000000;
  display: block;
  height: 0.64rem;
  margin: 0.693333rem 0.426667rem;
}

.input-modal .am-modal-content .am-modal-body .am-list-item {
  height: 1.28rem !important;
  min-height: 1.28rem !important;
  padding: 0;
}

.input-modal .am-modal-content .am-modal-body .am-list-item .am-list-line,
.am-modal-body .am-list-item.am-input-item {
  height: 1.28rem !important;
  min-height: 1.28rem !important;
  padding: 0 0.426667rem;
}

.info-modal .am-modal-button-group-h > .am-modal-button {
  font-size: 0.48rem;
  height: 1.333333rem;
  line-height: 1.333333rem;
}

.input-modal .am-modal-content .am-modal-body .am-list-item .am-list-line input {
  color: #666666;
  display: block;
  height: 1.28rem;
  margin: 0.693333rem 0;
  border-bottom: 1PX solid #efefef;
}

.input-modal .am-modal-footer {
  margin-top: 0.693333rem;
}

/*全局inputModal设置-end*/

/*全局inputModal设置-start*/

.button-modal {
  width: 8.266667rem !important;
}

.button-modal .am-modal-content {
  border-radius: 0.266667rem !important;
  padding-top: 0 !important;
}

.button-modal .am-modal-content .am-modal-body {
  padding: 0 !important;
  font-size: 0.453333rem !important;
}

.button-modal .am-modal-content .am-modal-body span {
  color: #000000;
  display: block;
  height: 0.64rem;
  margin: 0.693333rem 0.426667rem;
}

.button-modal .am-modal-content .am-modal-body .am-button {
  display: block;
  height: 1.386667rem;
  line-height: 1.386667rem;
  width: 100%;
  border-radius: 0;
}

.button-modal .am-modal-content .am-modal-body .am-button span {
  margin: 0;
  height: 1.386667rem;
  display: block;
  border-radius: 0;
}

.button-modal .am-modal-content .am-modal-body .am-button:nth-child(2) span {
  color: #0095FF;
}

.button-modal .am-modal-content .am-modal-body .am-button:nth-child(3) span {
  color: #FA555A;
}

.button-modal .am-modal-content .am-modal-body .am-button:last-child span {
  color: #000000;
}

/*全局inputModal设置-end*/

/*全局uploadPictureModal设置-start*/

.upload-picture-loading-modal .am-modal-content {
  background-color: transparent;
}

.upload-picture-cropper-modal {
  width: 100% !important;
}

.upload-picture-cropper-modal .am-modal-content {
  padding: 0 !important;
}

.upload-picture-cropper-modal .am-modal-content .am-modal-body {
  padding: 0 !important;
}

.upload-picture-cropper-modal .am-modal-content .am-modal-body span {
  margin: 0;
}

.upload-picture-cropper-modal .cropper-icon {
  width: 0.853333rem;
  height: 0.853333rem;
}

.upload-picture-cropper-modal .enable-button-inner-cancel {
  background: #ffffff !important;
  color: #000 !important;
  width: 50%;
  height: 1.066667rem !important;
  line-height: 1.066667rem !important;
  font-size: 0.453333rem !important;
  letter-spacing: 0.053333rem;
  border-radius: 0 !important;
}

.upload-picture-cropper-modal .enable-button-inner-ok {
  background: #2196F3 !important;
  color: #ffffff !important;
  width: 50%;
  height: 1.066667rem !important;
  line-height: 1.066667rem !important;
  font-size: 0.453333rem !important;
  letter-spacing: 0.053333rem;
  border-radius: 0 !important;
}

.upload-picture-cropper-modal .am-modal-content .am-modal-body .enable-button-inner-cancel span {
  color: #000000;
}

.upload-picture-cropper-modal .am-modal-content .am-modal-body .enable-button-inner-ok span {
  color: #ffffff;
}

/*全局uploadPictureModal设置-end*/

/* 全局header样式 start */

.header-box {
  width: 100%;
  height: 6vh;
  z-index: 1000;
  position: fixed;
  top: 0;
  background: #ededed;
}

.header-btn {
  padding-left: 0.426667rem;
  line-height: 5.2vh;
  text-align: left;
  width: 20%;
  float: left;
}

.header-title {
  line-height: 6vh;
  text-align: center;
  width: 100%;
  float: left;
}

/* 全局header样式 end */

/* 全局ant-list start */

.am-list-body::after,
.am-list-line::after,
.am-list-body::before,
.am-list-line::before {
  height: 0 !important;
}

.am-list-body .am-list-item,
.am-list-body > .am-list-item {
  background-color: transparent;
  min-height: 0;
  border-bottom: 1PX solid #efefef !important;
  padding: .28rem 0;
}

.am-list-item > .am-list-thumb > img {
  width: 1.28rem;
  height: 1.28rem;
}

.am-list-item.am-list-item-middle > .am-list-line {
  padding: 0;
}

.am-list-body > .am-list-item > .am-list-line > .am-list-content {
  font-weight: 400;
  color: #212121;
  font-size: 0.45rem;
  padding: 0;
  line-height: 1.5;
  font-size: 0.43rem;
}

.am-list-body > .am-list-item > .am-list-line > .am-list-extra {
  flex-basis: 60%;
  font-size: 0.45rem;
  font-weight: 400;
  color: #212121;
  padding: 0;
}

.am-list-body > .am-list-item > .am-list-line > .am-list-arrow {
  width: .4rem;
  height: .4rem;
  margin-left: .21rem;
}

.am-list-body > .am-list-item .am-list-thumb:first-child {
  margin-right: .19rem;
}

.am-list-content .am-icon-md {
  width: 0.34rem;
  height: 0.34rem;
}

/* 全局ant-list end */

/* 全局ant-tab start */

.am-tabs-tab-bar-wrap > .am-tab-bar-bar {
  height: 1.33rem;
  padding: .27rem 0 .2rem;
}

.am-tabs-tab-bar-wrap > .am-tab-bar-bar > .am-tab-bar-tab {
  flex: none;
  width: 33.33333%;
}

.am-tab-bar-bar .am-tab-bar-tab-title {
  font-size: 0.32rem !important;
  margin-top: 0.186667rem !important;
  text-align: center !important;
}

.am-tab-bar-bar .am-tab-bar-tab-image {
  width: 0.533333rem !important;
  height: 0.533333rem !important;
  vertical-align: middle !important;
}

/* 全局ant-tab end */

/* 全局字体大小 颜色 start*/

.font-size-12 {
  font-size: 0.32rem;
}

.font-size-14 {
  font-size: 0.373333rem;
}

.font-size-16 {
  font-size: 0.426667rem;
}

.font-size-18 {
  font-size: 0.48rem;
}

.font-size-20 {
  font-size: 0.533333rem;
}

.font-size-22 {
  font-size: 0.586667rem;
}

.font-color-21 {
  color: #212121;
}

.font-color-BD {
  color: #BDBDBD;
}

.font-color-75 {
  color: #757575;
}

.font-color-88 {
  color: #888888;
}

.font-color-19 {
  color: #1976D2;
}

.font-color-10 {
  color: #108EE9;
}

.font-color-black {
  color: #000000;
}

.bgc-ee {
  background-color: #EEEEEE;
}

.bgc-19 {
  background-color: #1976D2;
}

.bgc-21 {
  background-color: #2196F3;
}

/* 全局字体大小 颜色 end*/

/* .am-search start */

body .am-search {
  position: relative;
  display: flex;
  align-items: center;
  height: 1.173333rem;
  padding: 0 0.213333rem;
  overflow: hidden;
  background-color: #efeff4;
}

body .am-search.am-search-start .am-search-input input[type="search"] {
  padding: 0 0.746667rem 0 0.933333rem;
}

body > .am-search {
  background: #EFEFF4;
  height: 1.17rem;
}

body .am-search-input,
body .am-search-input input {
  font-size: 0.4rem !important;
  height: .75rem !important;
}

body .am-search-synthetic-ph-icon {
  font-size: 0.8rem !important;
}

body .am-search-synthetic-ph-placeholder {
  font-size: 0.4rem !important;
  line-height: .75rem !important;
}

body .am-search-cancel {
  font-size: 0.43rem;
}

/* .am-search end */