/* 动态表单 start */

.DYFormList {
  padding-bottom: 2.3rem;
}

.DYFormList>.am-list-body .am-list-item {
  min-height: .8rem;
  padding: .3rem .2rem;
  border-bottom: 1PX solid #efefef !important;
}

.DYFormList>.am-list-body .am-list-item .am-list-content {
  font-size: .43rem;
  color: #212121;
  padding: 0;
  line-height: 1.5;
  align-self: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.DYFormList>.am-list-body .am-list-line>.am-list-extra {
  font-size: .43rem;
  color: #212121;
  padding: 0;
  flex-basis: 60%;
}

/* 动态表单 end */

/* list css start*/

.list_model_box_wrap {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}

.list_model_box {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 6.1rem;
  background-color: white;
}

.list_model_box > h3 {
  font-size: 0.48rem;
  line-height: 1.333333rem;
  text-align: center;
}

.list_model_button_box {
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.list_model_button_box > a {
  width: 100%;
  background-color: #108ee9;
  color: white;
}

.list_model_wrap_box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper_warp_box {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0.266667rem 0 0 0.266667rem;
}

.swiper_warp_box > div {
  width: 30%;
  height: 3.2rem;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  align-content: space-between;
  border: 1PX solid #efefef;
  margin-right: 0.266667rem;
  padding: 0.266667rem 0;
}

.swiper_warp_box > div > .headPhotoImg {
  width: 1.333333rem;
  height: 1.333333rem;
  border-radius: 50%;
  line-height: 1.333333rem;
  text-align: center;
  color: white;
  margin: 0.266667rem 0;
}

.swiper_warp_box > div:last-child {
  border-bottom: 1PX solid #efefef;
}

.swiper_warp_box > div > svg {
  visibility: hidden;
}

.swiper_warp_box > div.active_item {
  border-color: green;
}

.swiper_warp_box > div.active_item > svg {
  visibility: visible;
}

.swiper_warp_box .am-list-item {
  border: none;
}

.swiper_warp_box .am-input-control {
  border-bottom: 1PX solid #efefef;
}

#root .am-list-body .am-list-item.swiper_box_input {
  border-bottom: none;
  padding: 0.266667rem 0.533333rem;
  border-bottom: 1PX solid #efefef;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0.533333rem !important;
}

/* list css end*/

/* card css start*/

.carNum_input {
  position: fixed;
  bottom: 65vw;
  left: 40vw;
  z-index: 10;
  visibility: hidden;
  font-size: 0.426667rem;
}

.show {
  animation: show .5s;
  visibility: visible;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

@keyframes show {
  from {
    visibility: visible;
    opacity: 1;
    transform: translateY(2.133333rem);
  }

  to {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

/* card css end*/